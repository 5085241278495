let _pageLoadBeaconSent = false,
	_TDO = { 
	"event": [],
	"getEventData": () => { return structuredClone( _TDO.event ); },
	"propositions": [],
	"addProposition": ( proposition ) => { 
		
		_TDO.propositions.push( proposition );

		if (  _pageLoadBeaconSent ) {
				
			_sendDecisionScopeNotification();

		}
	
	},
	"getPropositions": () => { return structuredClone( _TDO.propositions ); }	
};


/**
 * Called by the Experimentation team to pass data to analytics for reporting.
 * @param {string} key - The key of the data to be sent, generally the target activityid.
 * @param {string} value - The value of the data to be sent.
 * example: sendTargetData("1234","success");
 */
const sendTargetData = ( key = "", value = "" ) => {

	//Exit early if no data passed in
	if ( !key || !value ) {

		return false;

	}	
	
	_TDO.event.push( `${key}:${value}` );

	return true

};

const _eventPush = (obj) => {

	//Send data to analytics
	if (Array.isArray(window.top?.digitalData?.event)) {

		window.top.digitalData.event.push(obj);

	}

};

const _sendCustomTL = ( obj ) => {
	
	_eventPush({
		"category": {
			"primaryCategory": "content display"
		},
		"eventInfo": {
			"eventName": "target data"
		},
		"data": obj
	});

	if ( window._aape && _aape.PUB_SUB && typeof ( _aape.PUB_SUB.publish ) === "function" ) {

		_aape.PUB_SUB.publish( "thdcoreanalytics|logger", {
			"timestamp": Date(),
			"type": "info",
			"msg": "target Data layer TL call",
			"details": "",
			"tdo": obj,
			"trigger": "aape|beaconsent"
		} );

	}
}
const _sendDecisionScopeNotification = () => {

	_eventPush ({
		"category": {
			"primaryCategory": "batch"
		},
		"eventInfo": {
			"eventName": "target custom decision scope"
		},
		"propositionCount": _TDO.propositions.length
	});	

}

const init = () => {

	document.addEventListener('aape|beaconsent', function (event) {
		console.log(LOG_PREFIX + " -- aape|beaconsent: ", event);

		const data = event.detail || {},
			beaconType = data.beaconType || "";

		let eventData = "";

		if (beaconType === 't' && data.isPageLoadBeacon) {

			_pageLoadBeaconSent = true;

			eventData = data.v99 || _TDO.getEventData().join("|");

			if (eventData) _sendCustomTL(eventData);

		}

		//Clear out any propositions that were sent to analytics
		if (data.propositionLength) {

			_TDO.propositions.splice(0, data.propositionLength);

			_sendDecisionScopeNotification();
		}

	});

	return _TDO;
}

export {	
    init,
	sendTargetData
};
